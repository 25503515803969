/* global BigInt */

import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Avatar,
  Box,
  InputAdornment,
  CircularProgress,
  Grid,
  Chip,
  Snackbar,
  Alert,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { WalletContext } from '../WalletProvider'; // Adjust the import path as needed

// Styled Components

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '20px auto',
  padding: '20px',
  maxWidth: '1200px',
  borderRadius: '20px',
  [theme.breakpoints.down('sm')]: {
    padding: '15px',
    margin: '10px',
  },
}));

const MainGrid = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '100%',
  gap: '20px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const Column = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
}));

const StyledCard = styled(Card)(({ theme }) => ({
  border: '3px solid #000',
  padding: '25px',
  margin: '10px',
  textAlign: 'center',
  fontFamily: "'Schoolbell', cursive",
  boxShadow: '8px 8px 0px #000',
  backgroundColor: '#FFFFFF',
  borderRadius: '20px',
  width: '100%',
  maxWidth: '500px',
  boxSizing: 'border-box',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '12px 12px 0px #000',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
    width: '90%',
    margin: '15px 0',
  },
}));

const HeaderTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "'Schoolbell', cursive",
  color: '#333',
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '10px',
  textShadow: '2px 2px 4px #FFF',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  fontFamily: "'Schoolbell', cursive",
  marginTop: '15px',
  '& .MuiSelect-select': {
    padding: '12px',
    backgroundColor: '#FFF',
    borderRadius: '10px',
    border: '2px solid #000',
    boxShadow: '4px 4px 0px #000',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  fontFamily: "'Schoolbell', cursive",
  marginTop: '15px',
  '& .MuiInputBase-input': {
    fontFamily: "'Schoolbell', cursive",
    padding: '12px',
    backgroundColor: '#FFF',
    borderRadius: '10px',
    border: '2px solid #000',
    boxShadow: '4px 4px 0px #000',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .MuiInputAdornment-root': {
    fontFamily: "'Schoolbell', cursive",
  },
}));

const StyledButton = styled(Button)(({ theme, disabled }) => ({
  backgroundColor: disabled ? '#B0B0B0' : '#FF6347',
  color: '#FFFFFF',
  border: '3px solid #000',
  fontFamily: "'Schoolbell', cursive",
  padding: '12px 24px',
  minWidth: '140px',
  marginTop: '10px',
  '&:hover': {
    backgroundColor: disabled ? '#B0B0B0' : '#FFA500',
    color: disabled ? '#FFFFFF' : '#000',
  },
  boxShadow: '4px 4px 0px #000',
  borderRadius: '10px',
  fontSize: '1.1rem',
  textTransform: 'none',
  transition: 'background-color 0.3s, color 0.3s',
  [theme.breakpoints.down('sm')]: {
    flex: 1,
    minWidth: 'auto',
    padding: '10px 16px',
    fontSize: '1rem',
  },
}));

const SmallButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FF6347',
  color: '#FFFFFF',
  border: '2px solid #000',
  fontFamily: "'Schoolbell', cursive",
  padding: '6px 12px',
  marginLeft: '10px',
  '&:hover': {
    backgroundColor: '#FFA500',
    color: '#000',
  },
  boxShadow: '2px 2px 0px #000',
  borderRadius: '8px',
  fontSize: '0.9rem',
  textTransform: 'none',
  transition: 'background-color 0.3s, color 0.3s',
}));

const AssetIcon = styled('img')({
  width: 40,
  height: 40,
  borderRadius: '50%',
  border: '2px solid #000',
  objectFit: 'cover',
});

const BoxedAsset = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row', // Ensure items are in a row
  alignItems: 'flex-start', // Align items to the top
  justifyContent: 'flex-start', // Align items to the left
  padding: '12px',
  border: '2px solid #000',
  borderRadius: '10px',
  boxShadow: '4px 4px 0px #000',
  margin: '5px 0',
  backgroundColor: '#f9f9f9',
  height: '90px',
  boxSizing: 'border-box',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: '#e0e0e0',
  },
}));

const AssetText = styled(Typography)({
  fontFamily: "'Schoolbell', cursive",
  marginLeft: '12px',
  textAlign: 'left',
  fontSize: '1rem',
  color: '#333',
});

const TimerCard = styled(StyledCard)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginBottom: '0',
  },
}));

const TicketsSoldCard = styled(StyledCard)(({ theme }) => ({
  marginTop: '0',
  [theme.breakpoints.down('sm')]: {
    marginTop: '15px',
  },
}));

const PrizeDistributionCard = styled(StyledCard)(({ theme }) => ({
  marginTop: '0',
  [theme.breakpoints.down('sm')]: {
    marginTop: '15px',
  },
}));

// TicketBox and related styled components
const TicketBox = styled(Box)(({ theme }) => ({
  marginTop: '20px',
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: '20px 30px',
  border: '2px dashed #333',
  borderRadius: '10px',
  background: '#fff',
  width: '100%',
  maxWidth: '350px',
  textAlign: 'center',
  position: 'relative',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '&:before, &:after': {
    content: '""',
    position: 'absolute',
    width: '40px',
    height: '20px',
    background: '#fff',
    border: '2px solid #333',
    borderRadius: '20px',
  },
  '&:before': {
    top: '-10px',
    left: 'calc(50% - 20px)',
  },
  '&:after': {
    bottom: '-10px',
    left: 'calc(50% - 20px)',
  },
}));

const SerialNumber = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  right: '20px',
  fontSize: '0.8rem',
  color: '#666',
  fontFamily: 'monospace',
}));

const Barcode = styled('div')(({ theme }) => ({
  marginTop: '15px',
  width: '80%',
  height: '40px',
  backgroundImage: 'url(/barcode.png)', // Ensure you have a barcode image in your public folder
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}));

const TicketTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "'Schoolbell', cursive",
  fontSize: '1.8rem',
  color: '#333',
  marginBottom: '10px',
  textTransform: 'uppercase',
  letterSpacing: '2px',
}));

const TicketContent = styled(Typography)(({ theme }) => ({
  fontFamily: "'Schoolbell', cursive",
  color: '#333',
  fontSize: '1.2rem',
}));

// Prize Distribution Component
const PrizeDistribution = ({ prizes }) => (
  <PrizeDistributionCard>
    <Typography
      variant="h5"
      sx={{
        fontFamily: "'Schoolbell', cursive",
        color: '#333',
        fontSize: '1.8rem',
        marginBottom: '10px',
      }}
    >
      <strong>Lottery Prize Pool</strong>
    </Typography>
    <Grid container spacing={2} sx={{ marginTop: '10px' }}>
      {/* ERMO and ADA Prizes Side by Side */}
      {['ERMO', 'ADA'].map((asset) => (
        <Grid item xs={6} sm={6} key={asset}>
          <Box display="flex" flexDirection="column" alignItems="stretch">
            <BoxedAsset sx={{ width: '100%' }}>
              <AssetIcon src={asset === 'ERMO' ? '/ermo.png' : '/ada.png'} alt={asset} />
              <AssetText variant="h6">
                <strong>{asset}</strong>
              </AssetText>
            </BoxedAsset>
            {Object.entries(prizes[asset]).map(([key, value]) => (
              <BoxedAsset key={`${asset}-${key}`} sx={{ width: '100%' }}>
                <AssetIcon src={asset === 'ERMO' ? '/ermo.png' : '/ada.png'} alt={asset} />
                <AssetText variant="body2">
                  {formatPrizeLabel(key)}: {value !== undefined ? value.toLocaleString() : '0'}
                </AssetText>
              </BoxedAsset>
            ))}
          </Box>
        </Grid>
      ))}
    </Grid>
  </PrizeDistributionCard>
);

// Helper function to format prize labels
const formatPrizeLabel = (key) => {
  switch (key) {
    case 'firstPrize':
      return 'First Prize';
    case 'secondPrize':
      return 'Second Prize';
    case 'thirdPrize':
      return 'Third Prize';
    case 'burn':
      return 'Burn';
    case 'jackpot':
      return 'Jackpot';
    default:
      return key;
  }
};

// Timer Component
const Timer = ({ endTime, onCountdownEnd }) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    // Fetch current time from World Time API on initial load
    const fetchTime = async () => {
      const fetchedTime = await fetchCurrentTime();
      setCurrentTime(fetchedTime);
      const calculatedTimeLeft = calculateTimeLeft(fetchedTime, endTime);
      setTimeLeft(calculatedTimeLeft);
    };

    fetchTime();

    const timerInterval = setInterval(() => {
      setCurrentTime((prevTime) => {
        const newTime = new Date(prevTime.getTime() + 1000); // Increment by 1 second
        const calculatedTimeLeft = calculateTimeLeft(newTime, endTime);
        setTimeLeft(calculatedTimeLeft);

        if (!calculatedTimeLeft) {
          clearInterval(timerInterval); // Stop interval if countdown is over
          if (onCountdownEnd) {
            onCountdownEnd(); // Notify parent component
          }
        }

        return newTime;
      });
    }, 1000);

    return () => clearInterval(timerInterval); // Cleanup interval on unmount
  }, [endTime, onCountdownEnd]);

  if (!timeLeft) {
    return (
      <TimerCard>
        <Typography
          variant="h5"
          sx={{
            fontFamily: "'Schoolbell', cursive",
            color: '#333',
            fontSize: '1.5rem',
            marginBottom: '10px',
          }}
        >
          Lottery Ended
        </Typography>
      </TimerCard>
    );
  }

  return (
    <TimerCard>
      <Typography
        variant="h5"
        sx={{
          fontFamily: "'Schoolbell', cursive",
          color: '#333',
          fontSize: '1.5rem',
          marginBottom: '10px',
        }}
      >
        Lottery Ends In:
      </Typography>
      <Box display="flex" justifyContent="center" alignItems="center" gap="5px" flexWrap="wrap">
        <Box margin="0 5px" textAlign="center">
          <Typography
            variant="h4"
            sx={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              textShadow: '2px 2px 0px #000',
              color: '#FF4500',
            }}
          >
            {String(timeLeft.days).padStart(2, '0')}
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: '0.8rem', color: '#333', fontWeight: 'bold' }}
          >
            Days
          </Typography>
        </Box>
        <Typography variant="h5">:</Typography>
        <Box margin="0 5px" textAlign="center">
          <Typography
            variant="h4"
            sx={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              textShadow: '2px 2px 0px #000',
              color: '#FF4500',
            }}
          >
            {String(timeLeft.hours).padStart(2, '0')}
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: '0.8rem', color: '#333', fontWeight: 'bold' }}
          >
            Hours
          </Typography>
        </Box>
        <Typography variant="h5">:</Typography>
        <Box margin="0 5px" textAlign="center">
          <Typography
            variant="h4"
            sx={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              textShadow: '2px 2px 0px #000',
              color: '#FF4500',
            }}
          >
            {String(timeLeft.minutes).padStart(2, '0')}
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: '0.8rem', color: '#333', fontWeight: 'bold' }}
          >
            Minutes
          </Typography>
        </Box>
        <Typography variant="h5">:</Typography>
        <Box margin="0 5px" textAlign="center">
          <Typography
            variant="h4"
            sx={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              textShadow: '2px 2px 0px #000',
              color: '#FF4500',
            }}
          >
            {String(timeLeft.seconds).padStart(2, '0')}
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: '0.8rem', color: '#333', fontWeight: 'bold' }}
          >
            Seconds
          </Typography>
        </Box>
      </Box>
    </TimerCard>
  );
};

// Helper functions for Timer
const fetchCurrentTime = async () => {
  try {
    const response = await axios.get('https://worldtimeapi.org/api/ip');
    const data = response.data;
    return new Date(data.utc_datetime); // Return the UTC time
  } catch (error) {
    console.error('Error fetching current time:', error);
    return new Date(); // Fallback to local time if API fails
  }
};

const calculateTimeLeft = (currentTime, endTime) => {
  const endDate = new Date(endTime).getTime(); // Convert end time to UTC milliseconds
  const currentTimestamp = currentTime.getTime(); // Current UTC time in milliseconds

  const difference = endDate - currentTimestamp; // Time difference in milliseconds

  if (difference > 0) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };
  } else {
    return null; // Countdown is complete
  }
};

const Lottery = () => {
  const {
    lucid,
    walletAddress,
    isConnected,
    isInitializing,
    connectWallet,
    disconnectWallet,
  } = useContext(WalletContext); // Consume the wallet context

  const [customAmount, setCustomAmount] = useState('');
  const [selectedAsset, setSelectedAsset] = useState('ERMO');
  const [openPopup, setOpenPopup] = useState(false);
  const [popupDetails, setPopupDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [walletBalances, setWalletBalances] = useState({
    ERMO: 0,
    ADA: 0,
    jackpot: 0,
  });
  const [tickets, setTickets] = useState(0);
  const [endTime, setEndTime] = useState(null);
  const [isCountdownOver, setIsCountdownOver] = useState(false);
  const [ticketsSold, setTicketsSold] = useState({
    ERMO: 0,
    ADA: 0,
  });
  const [myTickets, setMyTickets] = useState({
    ERMO: null,
    ADA: null,
  }); // State to store user's tickets
  const [loadingMyTickets, setLoadingMyTickets] = useState(false); // Loading state for My Tickets
  const [openTicketDialog, setOpenTicketDialog] = useState(false); // State for ticket popup

  // New state to store total amounts from ticket transactions
  const [totalAmounts, setTotalAmounts] = useState({
    ERMO: 0,
    ADA: 0,
  });

  // Snackbar State
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', // 'error', 'warning', 'info', 'success'
  });

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleAssetChange = (event) => {
    setSelectedAsset(event.target.value);
    setCustomAmount('');
    setTickets(0);
  };

  // Define the ERMO Asset Identifier without the dot separator
  const ERMO_ASSET_IDENTIFIER =
    'e1a212122b07460a739af69751bd01cd03174791f778b2365cc0b8f645524d4f'; // Concatenated PolicyID and AssetNameHex

  // Asset Ticket Mapping
  const assetTicketMapping = {
    ERMO: {
      identifier: ERMO_ASSET_IDENTIFIER,
      multiple: 100, // 100 ERMO = 1 ticket
    },
    ADA: {
      identifier: 'lovelace',
      multiple: 10, // 10 ADA = 1 ticket
    },
  };

  const handleCustomAmountChange = (event) => {
    const value = event.target.value;
    setCustomAmount(value);

    // Calculate tickets based on input amount and selected asset
    const amount = Number(value);
    const requiredMultiple = assetTicketMapping[selectedAsset].multiple;

    if (!isNaN(amount) && amount >= requiredMultiple) {
      setTickets(Math.floor(amount / requiredMultiple));
    } else {
      setTickets(0);
    }
  };

  const handleCopyPayout = () => {
    if (popupDetails.txHash) {
      navigator.clipboard.writeText(popupDetails.txHash).then(
        () => {
          setSnackbar({
            open: true,
            message: 'Payout hash copied to clipboard!',
            severity: 'success',
          });
        },
        (err) => {
          console.error('Could not copy text: ', err);
          setSnackbar({
            open: true,
            message: 'Failed to copy hash.',
            severity: 'error',
          });
        }
      );
    }
  };

  const handleCreateTransaction = async () => {
    if (!isConnected) {
      setSnackbar({
        open: true,
        message: 'Please connect your wallet before creating a transaction.',
        severity: 'warning',
      });
      return;
    }

    if (!lucid) {
      setSnackbar({
        open: true,
        message: 'Lucid is not initialized. Please try again later.',
        severity: 'error',
      });
      return;
    }

    let amount = Number(customAmount);
    if (!selectedAsset) {
      setSnackbar({
        open: true,
        message: 'Please select an asset.',
        severity: 'warning',
      });
      return;
    }

    const requiredMultiple = assetTicketMapping[selectedAsset].multiple;

    if (!amount || isNaN(amount)) {
      setSnackbar({
        open: true,
        message: 'Please enter a valid amount.',
        severity: 'warning',
      });
      return;
    }
    if (amount < requiredMultiple) {
      setSnackbar({
        open: true,
        message: `Amount must be at least ${requiredMultiple} ${selectedAsset}.`,
        severity: 'warning',
      });
      return;
    }
    if (amount % requiredMultiple !== 0) {
      const roundedAmount = Math.round(amount / requiredMultiple) * requiredMultiple;
      setSnackbar({
        open: true,
        message: `Amount must be a multiple of ${requiredMultiple} ${selectedAsset}. Rounding to nearest ${requiredMultiple}: ${roundedAmount} ${selectedAsset}.`,
        severity: 'info',
      });
      setCustomAmount(roundedAmount);
      setTickets(Math.floor(roundedAmount / requiredMultiple));
      return;
    }

    const numTickets = amount / assetTicketMapping[selectedAsset].multiple; // Calculate number of tickets

    try {
      setLoading(true);
      // Fetch recipientAddress from Lottery.json
      const response = await axios.get(`${process.env.PUBLIC_URL}/Lottery.json`);
      const lotteryData = response.data;
      const recipientAddress = lotteryData.recipientAddress;

      if (!recipientAddress) {
        throw new Error('Recipient address is missing in Lottery.json.');
      }

      let txBuilder = lucid.newTx();

      if (selectedAsset === 'ERMO') {
        txBuilder = txBuilder.payToAddress(recipientAddress, {
          [assetTicketMapping['ERMO'].identifier]: BigInt(amount),
        });
      } else if (selectedAsset === 'ADA') {
        txBuilder = txBuilder.payToAddress(recipientAddress, {
          lovelace: BigInt(amount * 1000000), // Convert ADA to Lovelace
        });
      }

      // Attach metadata directly based on asset type
      txBuilder = txBuilder.attachMetadata(1, {
        lotteryId: 'lottery123',
        tickets: numTickets,
        assetType: selectedAsset,
      });

      const tx = await txBuilder.complete();
      const signedTx = await tx.sign().complete();
      const txHash = await signedTx.submit();
      console.log('Transaction submitted:', txHash);

      // Wait for the transaction to be confirmed
      await lucid.awaitTx(txHash);

      setPopupDetails({
        txHash,
        amount: amount,
        tickets: numTickets,
        asset: selectedAsset,
      });
      setOpenPopup(true);
      setSnackbar({
        open: true,
        message: 'Transaction successful!',
        severity: 'success',
      });
      setLoading(false);

      // Refresh data after transaction
      fetchLotteryData(); // Fetch updated total tickets sold
      handleMyTickets(); // Fetch updated tickets for the user
    } catch (error) {
      console.error('Error creating transaction:', error.message);
      setSnackbar({
        open: true,
        message: `Error creating the transaction: ${error.message}`,
        severity: 'error',
      });
      setLoading(false);
    }
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const fetchLotteryData = async () => {
    try {
      const response = await axios.get(`${process.env.PUBLIC_URL}/Lottery.json`);
      const lotteryData = response.data;

      // Get recipientAddress
      const recipientAddress = lotteryData.recipientAddress;

      if (!recipientAddress) {
        throw new Error('Recipient address is missing in Lottery.json.');
      }

      if (!lucid) {
        setSnackbar({
          open: true,
          message: 'Lucid is not initialized. Please try again later.',
          severity: 'error',
        });
        return;
      }

      // Fetch total tickets sold and total amounts from ticket transactions
      const totalTicketsData = await fetchTotalTicketsSold(recipientAddress);
      setTicketsSold({
        ERMO: totalTicketsData.ERMO.tickets,
        ADA: totalTicketsData.ADA.tickets,
      });

      console.log(
        `Tickets Sold - ERMO: ${totalTicketsData.ERMO.tickets}, ADA: ${totalTicketsData.ADA.tickets}`
      );

      // Use total amounts from ticket transactions to calculate prizes
      setTotalAmounts({
        ERMO: totalTicketsData.ERMO.totalAmount,
        ADA: totalTicketsData.ADA.totalAmount,
      });

      if (lotteryData.endTime) {
        setEndTime(lotteryData.endTime); // Using endTime from Lottery.json
      } else {
        console.error('endTime is missing in Lottery.json');
      }
    } catch (error) {
      console.error('Error fetching Lottery.json:', error);
      setSnackbar({
        open: true,
        message: 'Failed to fetch lottery data.',
        severity: 'error',
      });
    }
  };

  const fetchTotalTicketsSold = async (recipientAddress) => {
    try {
      // Replace with your Blockfrost API key (ensure it's securely stored)
      const blockfrostApiKey = process.env.REACT_APP_BLOCKFROST_API_KEY;

      if (!blockfrostApiKey) {
        throw new Error(
          'Blockfrost API key is missing. Please set it in your environment variables.'
        );
      }

      // Base URL for Blockfrost API
      const blockfrostBaseUrl = 'https://cardano-mainnet.blockfrost.io/api/v0';

      // Function to make authenticated requests to Blockfrost
      const blockfrostRequest = async (url, params = {}) => {
        return axios.get(url, {
          baseURL: blockfrostBaseUrl,
          headers: {
            project_id: blockfrostApiKey,
          },
          params,
        });
      };

      // Fetch transactions to the lottery address
      let page = 1;
      let txHashes = [];
      let fetchMore = true;

      // Handle pagination to fetch all transactions
      while (fetchMore) {
        const txPageResponse = await blockfrostRequest(
          `/addresses/${recipientAddress}/transactions`,
          {
            order: 'asc',
            page,
            count: 100,
          }
        );

        const txPage = txPageResponse.data;

        if (txPage.length > 0) {
          txHashes = txHashes.concat(txPage.map((tx) => tx.tx_hash));
          page++;
        } else {
          fetchMore = false;
        }
      }

      let ermoTickets = 0;
      let adaTickets = 0;
      let ermoTotalAmount = 0;
      let adaTotalAmount = 0;

      console.log(`Total transactions fetched for tickets: ${txHashes.length}`);

      for (const txHash of txHashes) {
        // Fetch transaction UTXOs to determine assets
        const utxosResponse = await blockfrostRequest(`/txs/${txHash}/utxos`);
        const utxos = utxosResponse.data;

        // Filter UTXOs sent to recipientAddress
        const sentUtxos = utxos.outputs.filter(
          (utxo) => utxo.address === recipientAddress
        );

        if (sentUtxos.length === 0) {
          console.log(`No UTXOs sent to recipient in transaction: ${txHash}`);
          continue; // No relevant UTXOs in this transaction
        }

        let hasERMO = false;
        let ermoAmount = 0;
        let adaAmount = 0;

        sentUtxos.forEach((utxo) => {
          const amountArray = utxo.amount;
          if (amountArray) {
            amountArray.forEach((amountObj) => {
              const assetKey = amountObj.unit;
              const quantity = amountObj.quantity;
              console.log(`Processing assetKey: ${assetKey}, amount: ${quantity}`);

              if (assetKey === assetTicketMapping['ERMO'].identifier) {
                hasERMO = true;
                ermoAmount += Number(quantity);
              }
              if (assetKey === 'lovelace') {
                adaAmount += Number(quantity);
              }
            });
          }
        });

        if (hasERMO) {
          // Count as ERMO tickets
          const ticketsFromTx = Math.floor(
            ermoAmount / assetTicketMapping['ERMO'].multiple
          );
          ermoTickets += ticketsFromTx;
          ermoTotalAmount += ermoAmount;
          console.log(`ERMO Ticket Added: ${ticketsFromTx} from TX: ${txHash}`);
        } else {
          // Count as ADA tickets
          const adaAmountInADA = adaAmount / 1000000; // Convert Lovelace to ADA
          const ticketsFromTx = Math.floor(
            adaAmountInADA / assetTicketMapping['ADA'].multiple
          );
          adaTickets += ticketsFromTx;
          adaTotalAmount += adaAmountInADA;
          console.log(`ADA Ticket Added: ${ticketsFromTx} from TX: ${txHash}`);
        }
      }

      console.log(
        `Total Tickets Sold - ERMO: ${ermoTickets}, ADA: ${adaTickets}`
      );
      console.log(
        `Total Amounts - ERMO: ${ermoTotalAmount}, ADA: ${adaTotalAmount}`
      );

      return {
        ERMO: { tickets: ermoTickets, totalAmount: ermoTotalAmount },
        ADA: { tickets: adaTickets, totalAmount: adaTotalAmount },
      };
    } catch (error) {
      console.error('Error fetching total tickets sold:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (lucid) {
      fetchLotteryData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lucid]);

  const handleMyTickets = async () => {
    if (!isConnected || !walletAddress) {
      setSnackbar({
        open: true,
        message: 'Please connect your wallet to view your tickets.',
        severity: 'warning',
      });
      return;
    }

    try {
      setLoadingMyTickets(true);

      const response = await axios.get(`${process.env.PUBLIC_URL}/Lottery.json`);
      const lotteryData = response.data;

      // Get recipientAddress
      const recipientAddress = lotteryData.recipientAddress;

      if (!recipientAddress) {
        throw new Error('Recipient address is missing in Lottery.json.');
      }

      // Replace with your Blockfrost API key (ensure it's securely stored)
      const blockfrostApiKey = process.env.REACT_APP_BLOCKFROST_API_KEY;

      if (!blockfrostApiKey) {
        throw new Error(
          'Blockfrost API key is missing. Please set it in your environment variables.'
        );
      }

      // Base URL for Blockfrost API
      const blockfrostBaseUrl = 'https://cardano-mainnet.blockfrost.io/api/v0';

      // Function to make authenticated requests to Blockfrost
      const blockfrostRequest = async (url, params = {}) => {
        return axios.get(url, {
          baseURL: blockfrostBaseUrl,
          headers: {
            project_id: blockfrostApiKey,
          },
          params,
        });
      };

      // Fetch transactions to the lottery address
      let page = 1;
      let txHashes = [];
      let fetchMore = true;

      // Handle pagination to fetch all transactions
      while (fetchMore) {
        const txPageResponse = await blockfrostRequest(
          `/addresses/${recipientAddress}/transactions`,
          {
            order: 'asc',
            page,
            count: 100,
          }
        );

        const txPage = txPageResponse.data;

        if (txPage.length > 0) {
          txHashes = txHashes.concat(txPage.map((tx) => tx.tx_hash));
          page++;
        } else {
          fetchMore = false;
        }
      }

      let ermoTickets = 0;
      let adaTickets = 0;

      console.log(
        `Total transactions fetched for user's tickets: ${txHashes.length}`
      );

      for (const txHash of txHashes) {
        // Fetch transaction UTXOs to determine assets
        const utxosResponse = await blockfrostRequest(`/txs/${txHash}/utxos`);
        const utxos = utxosResponse.data;

        // Check if the transaction is from the connected wallet
        const isFromWallet = utxos.inputs.some(
          (input) => input.address.toLowerCase() === walletAddress.toLowerCase()
        );

        if (isFromWallet) {
          // Filter UTXOs sent to recipientAddress
          const sentUtxos = utxos.outputs.filter(
            (utxo) => utxo.address.toLowerCase() === recipientAddress.toLowerCase()
          );

          if (sentUtxos.length === 0) {
            continue; // No relevant UTXOs in this transaction
          }

          let hasERMO = false;
          let ermoAmount = 0;
          let adaAmount = 0;

          sentUtxos.forEach((utxo) => {
            const amountArray = utxo.amount;
            if (amountArray) {
              amountArray.forEach((amountObj) => {
                const assetKey = amountObj.unit;
                const quantity = amountObj.quantity;
                console.log(`Processing assetKey: ${assetKey}, amount: ${quantity}`);

                if (assetKey === assetTicketMapping['ERMO'].identifier) {
                  hasERMO = true;
                  ermoAmount += Number(quantity);
                }
                if (assetKey === 'lovelace') {
                  adaAmount += Number(quantity);
                }
              });
            }
          });

          if (hasERMO) {
            // Count as ERMO tickets
            const ticketsFromTx = Math.floor(
              ermoAmount / assetTicketMapping['ERMO'].multiple
            );
            ermoTickets += ticketsFromTx;
            console.log(`User ERMO Ticket Added: ${ticketsFromTx} from TX: ${txHash}`);
          } else {
            // Count as ADA tickets
            const adaAmountInADA = adaAmount / 1000000; // Convert Lovelace to ADA
            const ticketsFromTx = Math.floor(
              adaAmountInADA / assetTicketMapping['ADA'].multiple
            );
            adaTickets += ticketsFromTx;
            console.log(`User ADA Ticket Added: ${ticketsFromTx} from TX: ${txHash}`);
          }
        }
      }

      console.log(`Total User Tickets - ERMO: ${ermoTickets}, ADA: ${adaTickets}`);

      setMyTickets({
        ERMO: ermoTickets,
        ADA: adaTickets,
      });
      setLoadingMyTickets(false);
      setOpenTicketDialog(true); // Open the ticket popup
    } catch (error) {
      console.error('Error fetching your tickets:', error);
      setSnackbar({
        open: true,
        message: 'Failed to fetch your tickets.',
        severity: 'error',
      });
      setLoadingMyTickets(false);
    }
  };

  // Generate a mock serial number for the ticket
  const generateSerialNumber = () => {
    // Simple random serial number generator
    return 'SN-' + Math.floor(100000 + Math.random() * 900000);
  };

  // Calculate prize distributions using totalAmounts from ticket transactions
  const calculatePrizes = (asset) => {
    const balance = totalAmounts[asset] || 0;
    const totalPrizesPool = balance * 0.9; // 90% of balance

    const firstPrize = totalPrizesPool * 0.5; // 50% of 90%
    const secondPrize = totalPrizesPool * 0.3; // 30% of 90%
    const thirdPrize = totalPrizesPool * 0.2; // 20% of 90%

    const burn = balance * 0.05; // 5% of balance for both ERMO and ADA
    const jackpot = balance * 0.05 + (walletBalances.jackpot || 0); // 5% of balance + jackpot from Lottery.json

    return {
      firstPrize: firstPrize || 0,
      secondPrize: secondPrize || 0,
      thirdPrize: thirdPrize || 0,
      burn: burn || 0, // Include burn for both ERMO and ADA
      jackpot: jackpot || 0,
    };
  };

  const prizes = {
    ERMO: calculatePrizes('ERMO'),
    ADA: calculatePrizes('ADA'),
  };

  const handleCloseTicketDialog = () => {
    setOpenTicketDialog(false);
    setMyTickets({ ERMO: null, ADA: null }); // Reset tickets
  };

  return (
    <Container>
      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <MainGrid>
        {/* Left Column */}
        <Column>
          {/* Timer */}
          {endTime && (
            <Timer endTime={endTime} onCountdownEnd={() => setIsCountdownOver(true)} />
          )}

          {/* Tickets Sold */}
          <TicketsSoldCard>
            <Typography
              variant="h5"
              sx={{
                fontFamily: "'Schoolbell', cursive",
                color: '#333',
                fontSize: '1.5rem',
                marginBottom: '10px',
              }}
            >
              Tickets Sold
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              {/* ERMO Tickets Sold */}
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Chip
                    label={`ERMO: ${
                      ticketsSold.ERMO !== undefined ? ticketsSold.ERMO.toLocaleString() : '0'
                    }`}
                    color="primary"
                    sx={{
                      fontFamily: "'Schoolbell', cursive",
                      fontSize: '1.2rem',
                      padding: '10px 20px',
                      borderRadius: '10px',
                      backgroundColor: '#FF6666', // Lighter red color for ERMO
                      color: '#FFF',
                      border: '3px solid #000',
                      boxShadow: '4px 4px 0px #000',
                    }}
                  />
                </Box>
              </Grid>
              {/* ADA Tickets Sold */}
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Chip
                    label={`ADA: ${
                      ticketsSold.ADA !== undefined ? ticketsSold.ADA.toLocaleString() : '0'
                    }`}
                    color="secondary"
                    sx={{
                      fontFamily: "'Schoolbell', cursive",
                      fontSize: '1.2rem',
                      padding: '10px 20px',
                      borderRadius: '10px',
                      backgroundColor: '#6699FF', // Lighter blue color for ADA
                      color: '#FFF',
                      border: '3px solid #000',
                      boxShadow: '4px 4px 0px #000',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            {/* "MY TICKETS" Button */}
            <Box display="flex" alignItems="center" justifyContent="center" marginTop="10px">
              <SmallButton onClick={handleMyTickets} disabled={loadingMyTickets}>
                {loadingMyTickets ? 'Loading...' : 'MY TICKETS'}
              </SmallButton>
            </Box>
          </TicketsSoldCard>

          {/* Unified Prize Distribution */}
          <PrizeDistribution prizes={prizes} />
        </Column>

        {/* Right Column */}
        <Column>
          {/* Lottery Card */}
          <StyledCard>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Avatar
                alt="Lottery"
                src={selectedAsset === 'ERMO' ? '/ermo.png' : '/ada.png'} // Dynamic image based on selected asset
                sx={{
                  width: 80,
                  height: 80,
                  marginBottom: '10px',
                  border: '3px solid #000',
                  boxShadow: '4px 4px 0px #000',
                }}
              />
              <HeaderTypography variant="h6">Lottery - {selectedAsset}</HeaderTypography>
            </Box>

            <Box height="2px" bgcolor="#000" margin="10px 0"></Box>

            <Box marginTop="15px">
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Schoolbell', cursive",
                  fontSize: '1rem',
                  color: '#333',
                }}
              >
                Participate in the lottery by betting {selectedAsset}. Each asset has its own ticket
                rate.
              </Typography>
            </Box>

            {/* Asset Selection */}
            <Box marginTop="15px">
              <StyledFormControl>
                <Select
                  value={selectedAsset}
                  onChange={handleAssetChange}
                  displayEmpty
                  renderValue={(value) => {
                    if (value) {
                      return value;
                    }
                    return 'Select Asset';
                  }}
                >
                  <MenuItem value="ERMO">ERMO</MenuItem>
                  <MenuItem value="ADA">ADA</MenuItem>
                  {/* Add other assets here when available */}
                </Select>
              </StyledFormControl>

              <Typography
                variant="body2"
                sx={{
                  fontFamily: "'Schoolbell', cursive",
                  color: '#333',
                  marginTop: '10px',
                }}
              >
                Enter the amount (multiples of {assetTicketMapping[selectedAsset].multiple}{' '}
                {selectedAsset}):
              </Typography>

              <StyledTextField
                type="number"
                value={customAmount}
                onChange={handleCustomAmountChange}
                placeholder={`Enter amount (multiple of ${assetTicketMapping[selectedAsset].multiple} ${selectedAsset})`}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{selectedAsset}</InputAdornment>,
                }}
              />

              {/* Display number of tickets */}
              {tickets > 0 && (
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "'Schoolbell', cursive",
                    color: '#333',
                    marginTop: '10px',
                  }}
                >
                  You will get {tickets} ticket{tickets > 1 ? 's' : ''}.
                </Typography>
              )}
            </Box>

            {/* Create Transaction Button */}
            <Box marginTop="20px">
              <StyledButton
                onClick={handleCreateTransaction}
                disabled={loading || isCountdownOver || !isConnected}
                startIcon={
                  loading ? <CircularProgress size={20} color="inherit" /> : <DownloadIcon />
                }
              >
                {loading ? 'Creating Transaction...' : 'Create Transaction'}
              </StyledButton>
              {!isConnected && (
                <Typography
                  variant="body2"
                  color="error"
                  sx={{ marginTop: '10px', fontFamily: "'Schoolbell', cursive" }}
                >
                  Please connect your wallet to participate.
                </Typography>
              )}
            </Box>

            <Box height="2px" bgcolor="#000" margin="15px 0"></Box>

            {/* Lottery Mechanics Description */}
            <Box textAlign="left">
              <Typography
                variant="body1"
                sx={{ fontFamily: "'Schoolbell', cursive", color: '#333', fontSize: '1rem' }}
              >
                <strong>How It Works:</strong>
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "'Schoolbell', cursive",
                  color: '#333',
                  fontSize: '0.9rem',
                  marginTop: '5px',
                }}
              >
                - Every {assetTicketMapping[selectedAsset].multiple} {selectedAsset} equals 1
                ticket.
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontFamily: "'Schoolbell', cursive", color: '#333', fontSize: '0.9rem' }}
              >
                - There are 3 winners.
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontFamily: "'Schoolbell', cursive", color: '#333', fontSize: '0.9rem' }}
              >
                - 5% is burned, 5% goes to the jackpot.
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontFamily: "'Schoolbell', cursive", color: '#333', fontSize: '0.9rem' }}
              >
                - First winner receives 50% of the prize pool.
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontFamily: "'Schoolbell', cursive", color: '#333', fontSize: '0.9rem' }}
              >
                - Second winner receives 30% of the prize pool.
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontFamily: "'Schoolbell', cursive", color: '#333', fontSize: '0.9rem' }}
              >
                - Third winner receives 20% of the prize pool.
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "'Schoolbell', cursive",
                  color: '#333',
                  fontSize: '0.9rem',
                  marginTop: '5px',
                }}
              >
                - There is a 1/100 chance to win the jackpot.
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontFamily: "'Schoolbell', cursive", color: '#333', fontSize: '0.9rem' }}
              >
                - If the jackpot is not won, it accumulates for the next week.
              </Typography>
            </Box>

            {/* Popup Dialog for Transaction Success */}
            <Dialog open={openPopup} onClose={handleClosePopup} fullWidth maxWidth="sm">
              <DialogTitle>Transaction Successful!</DialogTitle>
              <Box height="2px" bgcolor="#000" margin="10px 0"></Box>
              <DialogContent>
                <Box id="popup-details">
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "'Schoolbell', cursive",
                      marginBottom: '10px',
                      wordBreak: 'break-word',
                    }}
                  >
                    <strong>Transaction Hash:</strong>{' '}
                    {popupDetails.txHash ? popupDetails.txHash : 'N/A'}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "'Schoolbell', cursive", marginBottom: '10px' }}
                  >
                    <strong>Asset:</strong> {popupDetails.asset ? popupDetails.asset : 'N/A'}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "'Schoolbell', cursive", marginBottom: '10px' }}
                  >
                    <strong>Amount:</strong>{' '}
                    {popupDetails.amount !== undefined
                      ? popupDetails.amount.toLocaleString()
                      : '0'}{' '}
                    {popupDetails.asset}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "'Schoolbell', cursive", marginBottom: '10px' }}
                  >
                    <strong>Tickets:</strong>{' '}
                    {popupDetails.tickets !== undefined
                      ? popupDetails.tickets.toLocaleString()
                      : '0'}{' '}
                    Ticket{popupDetails.tickets > 1 ? 's' : ''}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "'Schoolbell', cursive", marginBottom: '10px' }}
                  >
                    <strong>Lottery Title:</strong> {popupDetails.asset} Lottery
                  </Typography>
                  <Box display="flex" justifyContent="center" marginTop="20px">
                    <Avatar
                      alt="Lottery"
                      src={popupDetails.asset === 'ERMO' ? '/ermo.png' : '/ada.png'} // Use asset-specific images
                      sx={{
                        width: 100,
                        height: 100,
                        border: '3px solid #000',
                        boxShadow: '4px 4px 0px #000',
                      }}
                    />
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <StyledButton
                      onClick={handleCopyPayout}
                      startIcon={<ContentCopyIcon />}
                      fullWidth
                    >
                      Copy Hash
                    </StyledButton>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledButton
                      onClick={handleClosePopup}
                      startIcon={<CloseIcon />}
                      fullWidth
                    >
                      Close
                    </StyledButton>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          </StyledCard>
        </Column>
      </MainGrid>

      {/* Ticket Popup Dialog */}
      <Dialog open={openTicketDialog} onClose={handleCloseTicketDialog} fullWidth maxWidth="sm">
        <DialogTitle>Your Lottery Tickets</DialogTitle>
        <Box height="2px" bgcolor="#000" margin="10px 0"></Box>
        <DialogContent>
          {myTickets.ERMO !== null && myTickets.ADA !== null && (
            <Box display="flex" flexDirection="column" alignItems="center">
              {/* ERMO Ticket */}
              {myTickets.ERMO > 0 && (
                <TicketBox>
                  <SerialNumber>{generateSerialNumber()}</SerialNumber>
                  <TicketTitle>ERMO Lottery Ticket</TicketTitle>
                  <TicketContent>
                    🎟️ You have {myTickets.ERMO} ticket{myTickets.ERMO !== 1 ? 's' : ''}! 🎟️
                  </TicketContent>
                  <Avatar
                    alt="ERMO"
                    src="/ermo.png"
                    sx={{ width: 60, height: 60, marginTop: '10px' }}
                  />
                  <Barcode />
                </TicketBox>
              )}
              {/* ADA Ticket */}
              {myTickets.ADA > 0 && (
                <TicketBox>
                  <SerialNumber>{generateSerialNumber()}</SerialNumber>
                  <TicketTitle>ADA Lottery Ticket</TicketTitle>
                  <TicketContent>
                    🎟️ You have {myTickets.ADA} ticket{myTickets.ADA !== 1 ? 's' : ''}! 🎟️
                  </TicketContent>
                  <Avatar
                    alt="ADA"
                    src="/ada.png"
                    sx={{ width: 60, height: 60, marginTop: '10px' }}
                  />
                  <Barcode />
                </TicketBox>
              )}
              {/* No Tickets */}
              {myTickets.ERMO === 0 && myTickets.ADA === 0 && (
                <Typography
                  variant="body1"
                  sx={{ fontFamily: "'Schoolbell', cursive", marginTop: '20px' }}
                >
                  You have no tickets.
                </Typography>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleCloseTicketDialog} startIcon={<CloseIcon />} fullWidth>
            Close
          </StyledButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Lottery;
